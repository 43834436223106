import { ADD_TAX_TYPE, UPDATE_TAX_TYPE, REMOVE_TAX_TYPE } from '../types';

const initialState = []

const taxTypes = (state = initialState, action) => {
    switch(action.type) {
        case ADD_TAX_TYPE:
            const addedState = [...state]
            for (const obj of action.payload) {
                addedState.push(obj)
            }
            return [...addedState]
        case UPDATE_TAX_TYPE:
            if (action.payload.taxCode) {
                const modifiedState = [
                    ...state.filter(obj => obj.taxCode && obj.taxCode !== action.payload.taxCode)
                ]
                modifiedState.push(action.payload)
                return [...modifiedState]
            }
            break;
        case REMOVE_TAX_TYPE:
            let removedState = [...state]
            removedState = removedState.filter(obj => obj.taxCode !== action.payload.taxCode)
            return [...removedState]
        default:
            return [ ...state ];
    }
}

export default taxTypes