import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'


import MainLayout from '../../components/MainLayout'
import Dashboard from '../Dashboard'
import FileTaxReturn from '../TaxSubmission'
import TaxReviewList from '../TaxReviewList'
import TaxReviewDetails from '../TaxReviewList/ReviewList'
import CompanyMasterlist from '../Companies'
import TaxTypeMasterlist from '../TaxType'
import UsersMasterlist from '../Users'
import PageNotFound from '../PageNotFound'

function Index() {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)

    useEffect(() => {
        return () => {
            
        }
    }, [dispatch])

    return auth.isLoggedIn ? (
        <MainLayout>
            <Switch>
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/file_tax_review" component={FileTaxReturn} />
                <Route path="/tax_review_list/:status/:ticketid" component={TaxReviewDetails} />
                <Route path="/tax_review_list/:status" component={TaxReviewList} />
                <Route exact path="/company_masterlist" component={CompanyMasterlist} />
                <Route exact path="/tax_type_masterlist" component={TaxTypeMasterlist} />
                <Route exact path="/users_masterlist" component={UsersMasterlist} />
                <Route component={PageNotFound}/>
            </Switch>
        </MainLayout>
    ) : (
        <Redirect to="/login"/>
    )
}

export default Index
