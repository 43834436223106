import React, { useState, useEffect } from 'react'
import { Col, Button, Row, Input, Table, Typography } from 'antd'

import CompanyHeader from './CompanyHeader'
import { useSelector } from 'react-redux'

const { Title } = Typography
const { Search } = Input

const columns = [
    {
        title: "Form Type",
        dataIndex: "formType",
        key: "formType",
        width: "10%"
    },
    {
        title: "Group Type",
        dataIndex: "groupType",
        key: "groupType",
        width: "10%"
    },
    {
        title: "Short Name",
        dataIndex: "shortName",
        key: "shortName",
        width: "10%"
    },
    {
        title: "Company Name",
        dataIndex: "companyName",
        key: "companyName",
        width: "40%"
    },
    {
        title: "Process Owner",
        dataIndex: "processOwner",
        key: "processOwner",
        width: "15%"
    },
    {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
        width: "15%"
    }
]

function Index() {
    const [record, setRecord] = useState(null)
    const [action, setAction] = useState(null)
    const companies = useSelector(state => state.companies)
    const auth = useSelector(state => state.auth)
    const [companyList, setCompanyList] = useState(companies)

    useEffect(() => {
        setCompanyList(companies)
    }, [companies])

    const addCompany = (act, data) => {
        setAction(act)
        setRecord(data)
    }

    const closeModal = () => {
        setRecord(null)
        setAction(null)
    }

    const onSearch = values => {
        const searchValue = values.toLowerCase()
        const filter = companies.filter(user => Object.values(user).some(val => typeof val === "string" && val.toLowerCase().includes(searchValue)));
        setCompanyList(filter)
    }

    return (
        <div style={{ background: 'white', padding: '24px' }}>
            <Title level={4}>Companies Masterlist</Title>
            {
                action === null
                    ? (<Row justify="end" align="middle" gutter={8}>
                        { auth.accessRole === "System Admin" ? <Col><Button onClick={() => addCompany("Add")} type="primary">Add Company</Button></Col> : <></> }
                        <Col><Search onSearch={onSearch} allowClear /></Col>
                    </Row>)
                    : (<CompanyHeader action={action} closeHeader={() => closeModal()} record={record} setAction={(e) => setAction(e)} />)
            }
            <br />
            <Table 
                style={{ cursor: 'pointer' }}
                columns={columns} 
                dataSource={companyList}
                bordered
                onRow={(data) => {
                    return {
                        onClick: event => {
                            setRecord(data)
                            addCompany('View', data)
                        }
                    }
                }}
            />
        </div>
    )
}

export default Index
