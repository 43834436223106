import firebase from '../firebaseConfig'
import { actions } from '../redux'

const db = firebase.firestore();

export const taxTypeObserver = arg => {
    const collectionName = "taxTypes"
    const dispatch = arg[0];
    const collection = db.collection(collectionName)

    collection.onSnapshot(querySnapshot => {
        const taxTypeList = [];
        for(const change of querySnapshot.docChanges()) {
            const taxTypeData = {
                ...change.doc.data()
            }
            
            switch(change.type) {
                case "modified":
                    dispatch(actions.UPDATE_TAX(taxTypeData))
                    break;
                case "removed":
                    dispatch(actions.REMOVE_TAX(taxTypeData))
                    break;
                case "added":
                    taxTypeList.push(taxTypeData)
                    break;
                default:
                    break;
            }
        }
        if(taxTypeList.length > 0) {
            dispatch(actions.ADD_TAX(taxTypeList))
        }
    },
    err => {
        console.log(err)
    })
}

export const companiesObserver = arg => {
    const collectionName = 'companies';
    const dispatch = arg[0];
    const collection = db.collection(collectionName)

    collection.onSnapshot(querySnapshot => {
        const companyList = [];
        for (const change of querySnapshot.docChanges()) {
            const companyData = {
                ...change.doc.data()
            }

            switch(change.type) {
                case "modified":
                    dispatch(actions.UPDATE_COMPANIES(companyData))
                    break;
                case "removed":
                    dispatch(actions.REMOVE_COMPANIES(companyData))
                    break;
                case "added":
                    companyList.push(companyData)
                    break;
                default:
                    break;
            }
        }
        if(companyList.length > 0) {
            dispatch(actions.ADD_COMPANIES(companyList))
        }
    },
    err => {
        console.log(err)
    })
}

export const taxReviewsObserver = arg => {
    const collectionName = 'taxReviews';
    const dispatch = arg[0];
    const collection = db.collection(collectionName)

    collection.onSnapshot(querySnapshot => {
        const taxReviewList = [];
        for (const change of querySnapshot.docChanges()) {
            const taxReviewData = {
                ...change.doc.data()
            }

            switch(change.type) {
                case "modified":
                    dispatch(actions.UPDATE_TAX_REVIEWS(taxReviewData))
                    break;
                case "removed":
                    dispatch(actions.REMOVE_TAX_REVIEWS(taxReviewData))
                    break;
                case "added":
                    taxReviewList.push(taxReviewData)
                    break;
                default:
                    break;
            }
        }
        if(taxReviewList.length > 0) {
            dispatch(actions.ADD_TAX_REVIEWS(taxReviewList))
        }
    },
    err => {
        console.log(err)
    })
}

export const usersObserver = arg => {
    const collectionName = "users";
    const dispatch = arg[0];
    const collection = db.collection(collectionName)

    collection.onSnapshot(querySnapshot => {
        const userList = [];
        for (const change of querySnapshot.docChanges()) {
            const userData = {
                ...change.doc.data()
            }

            switch(change.type) {
                case "modified":
                    dispatch(actions.UPDATE_USERS(userData))
                    break;
                case "removed":
                    dispatch(actions.REMOVE_USERS(userData))
                    break;
                case "added":
                    userList.push(userData)
                    break;
                default:
                    break;
            }
        }
        if(userList.length > 0) {
            dispatch(actions.ADD_USERS(userList))
        }
    },
    err => {
        console.log(err)
    })
}