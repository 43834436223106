import {
    SIGNED_IN,
    SIGNED_OUT,
    ADD_TAX_TYPE,
    UPDATE_TAX_TYPE,
    REMOVE_TAX_TYPE,
    ADD_COMPANY,
    UPDATE_COMPANY,
    REMOVE_COMPANY,
    ADD_TAX_REVIEW,
    UPDATE_TAX_REVIEW,
    REMOVE_TAX_REVIEW,
    ADD_USER,
    UPDATE_USER,
    REMOVE_USER
} from './types'

// AUTHENTICATION
export const signedIn = payload => {
    return {
        type: SIGNED_IN,
        payload
    }
}

export const signedOut = () => {
    return {
        type: SIGNED_OUT
    }
}

// TAX TYPE
export const ADD_TAX = payload => {
    return {
        type: ADD_TAX_TYPE,
        payload
    }
}

export const UPDATE_TAX = payload => {
    return {
        type: UPDATE_TAX_TYPE,
        payload
    }
}

export const REMOVE_TAX = payload => {
    return {
        type: REMOVE_TAX_TYPE,
        payload
    }
}

// TAX TYPE
export const ADD_COMPANIES = payload => {
    return {
        type: ADD_COMPANY,
        payload
    }
}

export const UPDATE_COMPANIES = payload => {
    return {
        type: UPDATE_COMPANY,
        payload
    }
}

export const REMOVE_COMPANIES = payload => {
    return {
        type: REMOVE_COMPANY,
        payload
    }
}

// TAX REVIEW
export const ADD_TAX_REVIEWS = payload => {
    return {
        type: ADD_TAX_REVIEW,
        payload
    }
}

export const UPDATE_TAX_REVIEWS = payload => {
    return {
        type: UPDATE_TAX_REVIEW,
        payload
    }
}

export const REMOVE_TAX_REVIEWS = payload => {
    return {
        type: REMOVE_TAX_REVIEW,
        payload
    }
}

// USERS
export const ADD_USERS = payload => {
    return {
        type: ADD_USER,
        payload
    }
}

export const UPDATE_USERS = payload => {
    return {
        type: UPDATE_USER,
        payload
    }
}

export const REMOVE_USERS = payload => {
    return {
        type: REMOVE_USER,
        payload
    }
}