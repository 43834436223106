import { SIGNED_IN, SIGNED_OUT } from '../types'

const initialState = {
    isLoggedIn: false,
    name: '',
    email: '',
    photoURL: '',
    phoneNumber: '',
    uid: '',
    msid: ''
}

const auth = (state = initialState, action) => {
    switch(action.type) {
        case SIGNED_IN:
            return { ...action.payload, isLoggedIn: true}
        case SIGNED_OUT: 
            return initialState
        default:
            return state;
    }
}

export default auth