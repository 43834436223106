import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Space, Button, Select } from 'antd'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import './index.less'
import firebase from '../../firebaseConfig'

const { Option } = Select;
const collection = firebase.firestore().collection("taxTypes")

const frequencyList = [
    "Monthly (12)",
    "Monthly (8)",
    "Quarterly (4)",
    "Quarterly (3)",
    "Annual"
]

function TaxHeader({ closeHeader, record, action, setAction }) {
    const companies = useSelector(state => state.companies)
    const auth = useSelector(state => state.auth)
    const [form] = Form.useForm()
    const [disable, setDisable] = useState(true)
    const [initialState, setInitialState] = useState(record)
    const [isUsed, setIsUsed] = useState(false)

    useEffect(() => {
        setInitialState(record)
        form.setFieldsValue(record)
        const usageCount = companies.filter(company => company.taxTypes.includes(record?.taxCode))
        
        if(usageCount.length > 0) {
            setIsUsed(true)
        }

    }, [record])

    const ActionButtons = () => {
        switch(action) {
            case "Add":
                return (
                    <Space>
                        <Button type="primary" onClick={() => form.submit()}>Add</Button>
                        <Button onClick={() => closeHeader()} >Cancel</Button>
                    </Space>
                )
            case "View":
                return (
                    <Space>
                        {
                            auth.accessRole === "System Admin"
                                ? <>
                                    <Button onClick={() => {setDisable(false); setAction("Update")}} type="primary">Edit</Button>
                                    {
                                        isUsed
                                            ? record?.isDisabled 
                                                ? <Button onClick={() => enableTaxType(record)}>Enable</Button>
                                                : <Button onClick={() => disableTaxType(record)}>Disable</Button>
                                            : <Button onClick={() => deleteData(record)}>Delete</Button>
                                    }
                                </>
                                : <></>
                        }
                        <Button onClick={() => closeHeader()} >Close</Button>
                    </Space>
                )
            case "Update":
                return (
                    <Space>
                        <Button onClick={() => form.submit()} type="primary">Save</Button>
                        <Button onClick={() => {setDisable(true); setAction("View"); form.resetFields()}} >Cancel</Button>
                    </Space>
                )
            default:
                break;
        }
    }

    const enableTaxType = record => {
        console.log(record)
        Swal.fire({
            title: "Enable Tax Type",
            text: `Are you sure you want to enable ${record.taxName}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection
                    .doc(record.taxCode)
                    .set({ 
                        ...record,
                        isDisabled: false
                        
                })
                .then(() => 
                    Swal.fire({ icon: "success"})
                        .then(() => closeHeader())
                )
            }
        })
    }

    const disableTaxType = record => {
        console.log(record)
        Swal.fire({
            title: "Disable Tax Type",
            text: `Are you sure you want to disable ${record.taxName}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection
                    .doc(record.taxCode)
                    .set({ 
                        ...record,
                        isDisabled: true
                        
                })
                .then(() => 
                    Swal.fire({ icon: "success"})
                        .then(() => closeHeader())
                )
            }
        })
    }

    const deleteData = record => {
        Swal.fire({
            title: "Delete?",
            text: `Are you sure you want to delete ${record.taxName}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection.doc(record.taxCode).delete()
                    .then((_) => {
                        setDisable(true)
                        setAction(null)
                        Swal.fire({ icon: "success"})
                            .then(() => closeHeader())
                    })
                    .catch(err => console.log(err))
            }
        })
    }

    const onFinish = data => {
        collection.doc(data.taxCode).set(data)
            .then(() => {
                setDisable(true)
                if(action === "Add") {
                    setAction(null)
                } else {
                    setAction("View")
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <div className="tax-header-container">
            <div className="action-bar">
                <div className="action-header">{record === undefined ? "Add Tax Type" : record?.taxName}</div>
                <div className="action-buttons">
                    <ActionButtons />
                </div>
            </div>
            <br />
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                initialValues={initialState}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Tax Code"
                            name="taxCode"
                            rules={[{ required: true, message: "Please enter tax code" }]}
                        >
                            <Input placeholder="Enter Tax Code" readOnly={action === "Add" ? false : disable} />
                        </Form.Item>
                        <Form.Item
                            label="Tax Name"
                            name="taxName"
                            rules={[{ required: true, message: "Please enter tax name" }]}
                        >
                            <Input placeholder="Enter Tax Name" readOnly={action === "Add" ? false : disable}/>
                        </Form.Item>
                        <Form.Item
                            label="Frequency"
                            name="frequency"
                            rules={[{ required: true, message: "Please select frequency" }]}
                        >
                            <Select placeholder="Select Frequency" disabled={action === "Add" ? false : disable} >
                                {frequencyList.map(frequency => (<Option value={frequency}>{frequency}</Option>))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tax Team Deadline"
                            name="taxTeamDeadline"
                            rules={[{ required: true, message: "Please enter tax team deadline" }]}
                        >
                            <Input type="number" min="1" max="31" placeholder="# of days prior to due date" readOnly={action === "Add" ? false : disable}/>
                        </Form.Item>
                        <Form.Item
                            label="Attachment Deadline"
                            name="attachmentDeadline"
                            rules={[{ message: "Please enter attachment deadline" }]}
                        >
                            <Input type="number" min="1" max="31" placeholder="Enter Attachment Submission Deadline" readOnly={action === "Add" ? false : disable}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="eBIR Deadline"
                            name={["birDeadline", "eBIRform"]}
                            rules={[{ message: "Please enter eBIR deadline" }]}
                        >
                            <Input type="number" min="1" max="31" placeholder="Enter eBIR Deadline" readOnly={action === "Add" ? false : disable}/>
                        </Form.Item>
                        <Form.Item label="eFPS Deadline">
                            <Input.Group>
                                <Form.Item
                                    label="Group A"
                                    name={["birDeadline", "eFPS", "groupA"]}
                                    rules={[{ message: "Please enter eFPS group a deadline" }]}
                                >
                                    <Input type="number" min="1" max="31" placeholder="Enter eFPS: Group A Deadline" readOnly={action === "Add" ? false : disable}/>
                                </Form.Item>
                                <Form.Item
                                    label="Group B"
                                    name={["birDeadline", "eFPS", "groupB"]}
                                    rules={[{ message: "Please enter eFPS group b deadline" }]}
                                >
                                    <Input type="number" min="1" max="31" placeholder="Enter eFPS: Group B Deadline" readOnly={action === "Add" ? false : disable}/>
                                </Form.Item>
                                <Form.Item
                                    label="Group C"
                                    name={["birDeadline", "eFPS", "groupC"]}
                                    rules={[{ message: "Please enter eFPS group c deadline" }]}
                                >
                                    <Input type="number" min="1" max="31" placeholder="Enter eFPS: Group C Deadline" readOnly={action === "Add" ? false : disable}/>
                                </Form.Item>
                                <Form.Item
                                    label="Group D"
                                    name={["birDeadline", "eFPS", "groupD"]}
                                    rules={[{ message: "Please enter eFPS group d deadline" }]}
                                >
                                    <Input type="number" min="1" max="31" placeholder="Enter eFPS: Group D Deadline" readOnly={action === "Add" ? false : disable}/>
                                </Form.Item>
                                <Form.Item
                                    label="Group E"
                                    name={["birDeadline", "eFPS", "groupE"]}
                                    rules={[{ message: "Please enter eFPS group e deadline" }]}
                                >
                                    <Input type="number" min="1" max="31" placeholder="Enter eFPS: Group E Deadline" readOnly={action === "Add" ? false : disable}/>
                                </Form.Item>
                            </Input.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default TaxHeader
