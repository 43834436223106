import { ADD_TAX_REVIEW, UPDATE_TAX_REVIEW, REMOVE_TAX_REVIEW } from '../types'

const initialState = []

const taxReviews = (state = initialState, action) => {
    switch(action.type) {
        case ADD_TAX_REVIEW:
            const addedState = [...state]
            for (const obj of action.payload) {
                addedState.push(obj)
            }
            return [...addedState]
        case UPDATE_TAX_REVIEW:
            if (action.payload._id) {
                const modifiedState = [
                    ...state.filter(obj => obj._id && obj._id !== action.payload._id)
                ]
                modifiedState.push(action.payload)
                return [...modifiedState]
            }
            break;
        case REMOVE_TAX_REVIEW:
            let removedState = [...state]
            removedState = removedState.filter(obj => obj._id !== action.payload._id)
            return [...removedState]
        default:
            return [...state]
    }
}

export default taxReviews