import { combineReducers } from "redux";
import auth from  './reducers/auth'
import taxTypes from './reducers/taxTypes'
import companies from './reducers/companies'
import taxReviews from './reducers/taxReviews'
import users from './reducers/users'

const appReducer = combineReducers({
    auth,
    taxTypes,
    companies,
    taxReviews,
    users
})

const rootReducer = (state, action) => {
    if(action.type === 'SIGNED_OUT') {
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer