import React from 'react'
import { Image, Typography, Button, message } from 'antd'
import Icon from '@ant-design/icons'
import { useDispatch } from 'react-redux'

import './index.less'
import megawide_banner from '../../assets/images/megawide_banner.png'
import firebase from '../../firebaseConfig'
import { actions } from '../../redux'
import { useHistory } from 'react-router-dom'
import { companiesObserver, taxTypeObserver, taxReviewsObserver, usersObserver } from '../../services/observers'
import { graphConfig } from '../../services/authConfig'

const { Title } = Typography;

const db = firebase.firestore()

const MicrosoftSvg = () => (
    <svg viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor">
        <path fill="#4CAF50" d="M272,240h240V16c0-8.832-7.168-16-16-16H272V240z"/>
        <path fill="#F44336" d="M240,240V0H16C7.168,0,0,7.168,0,16v224H240z"/>
        <path fill="#2196F3" d="M240,272H0v224c0,8.832,7.168,16,16,16h224V272z"/>
        <path fill="#FFC107" d="M272,272v240h224c8.832,0,16-7.168,16-16V272H272z"/>
    </svg>
)

const MicrosoftIcon = props => <Icon component={MicrosoftSvg} { ...props } />

const provider = new firebase.auth.OAuthProvider('microsoft.com');

function Login() {
    const dispatch = useDispatch();
    const history = useHistory();

    provider.setCustomParameters({
        tenant: process.env.REACT_APP_MICROSOFT_TENANT_ID
    })

    provider.addScope('User.Read')
    provider.addScope("User.ReadBasic.All")

    const login = () => {
        firebase.auth().signInWithPopup(provider)
            .then(result => {
                db.collection("users").doc(result.user.email).get()
                    .then(doc => {
                        if(doc.exists) {
                            callMsGraph(result.credential.accessToken)
                                .then(res => {
                                    getProfileImage(result.credential.accessToken)
                                        .then(rsp => {
                                            const url = window.URL || window.webkitURL
                                            const blobUrl = url.createObjectURL(rsp);

                                            const payload = {
                                                isLoggedIn: true,
                                                avatar: blobUrl,
                                                ...doc.data()
                                            }
                                            
                                            taxTypeObserver([dispatch])
                                            companiesObserver([dispatch])
                                            taxReviewsObserver([dispatch])
                                            usersObserver([dispatch])
                                            
                                            dispatch(actions.signedIn(payload))
                                            message.success("Logged in successfully.", 5)
                                            history.push("/dashboard")
                                        })
                                        .catch(err => console.log(err))
                                })
                                .catch(err => console.log(err))
                        } else {
                            message.warning("User not allowed. Contact helpdesk to request access.", 5)
                        }
                    })
            })
            .catch(err => {
                console.log(err)
                message.error("Something went wrong. Contact helpdesk for support.", 5)
            })
    }

    const getProfileImage = (accessToken) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
    
        return fetch(graphConfig.graphMePhotoEndpoint, options)
            .then(response => response.blob())
            .catch(error => console.log('error', error));
    }

    const callMsGraph = (accessToken) => {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
    
        headers.append("Authorization", bearer);
    
        const options = {
            method: "GET",
            headers: headers
        };
    
        return fetch(graphConfig.graphMeEndpoint, options)
            .then(response => response.json())
            .catch(error => console.log(error));
    }

    return (
        <div className="login-container">
            <div className="login-form">
                <Image src={megawide_banner} width='50%' preview={false}/>
                <Title className="login-title">TAX RETURN REVIEW PORTAL</Title>
                <Title className="login-subtitle" level={4}>OFFICE OF THE CHIEF FINANCIAL OFFICER</Title>
                <Button className="login-button" icon={<MicrosoftIcon />} size='large' onClick={() => login()}>Login with Microsoft</Button>
            </div>
        </div>
    )
}

export default Login
