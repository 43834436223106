import { ADD_USER, UPDATE_USER, REMOVE_USER } from '../types'

const initialState = []

const users = (state = initialState, action) => {
    switch(action.type) {
        case ADD_USER:
            const addedState = [...state]
            for (const obj of action.payload) {
                addedState.push(obj)
            }
            return [...addedState]
        case UPDATE_USER:
            if (action.payload.email) {
                const modifiedState = [
                    ...state.filter(obj => obj.email && obj.email !== action.payload.email)
                ]
                modifiedState.push(action.payload)
                return [...modifiedState]
            }
            break;
        case REMOVE_USER:
            let removedState = [...state]
            removedState = removedState.filter(obj => obj.email !== action.payload.email)
            return [...removedState]
        default:
            return [...state]
    }
}

export default users