import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Table, Typography, Input } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { formatDate } from '../../services/dataHandlers'
import ReviewList from './ReviewList'

const { Title } = Typography
const { Search } = Input

const columns = [
    {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        width: '10%',
        render: (text, record) => (<span style={{ color: record.isLate ? "red" : "" }}>{text}</span>)
    },
    {
        title: "Company",
        dataIndex: "company",
        key: "company",
        width: '30%'
    },
    {
        title: "Group Type",
        dataIndex: "groupType",
        key: "groupType",
        width: '10%'
    },
    {
        title: "Tax Type",
        dataIndex: "taxType",
        key: "taxType",
        width: '10%'
    },
    {
        title: "Tax Period",
        dataIndex: "taxablePeriod",
        key: "taxablePeriod",
        width: '10%'
    },
    {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: '15%'
    },
    {
        title: "Date Submitted",
        dataIndex: "dateSubmitted",
        key: "dateSubmitted",
        width: '15%',
        defaultSortOrder: 'descend',
        sortDirections: ['descend'],
        sorter: (a, b) => a.dateSubmitted - b.dateSubmitted,
        render: (text) => {
            return formatDate(text?.toDate()?.toString())
        }
    },
]

function TaxReviewList(props) {
    const history = useHistory()
    const taxReviews = useSelector(state => state.taxReviews)
    const auth = useSelector(state => state.auth)
    const [visible, setVisible] = useState(false)
    const [record, setRecord] = useState(null)
    const [reviewList, setReviewList] = useState([])
    const [capPage, setCapPage] = useState("")

    useEffect(() => {
        const pageStatus = props.match.params?.status
        setCapPage(pageStatus.charAt(0).toUpperCase() + pageStatus.slice(1))

        const filter = auth.accessRole === "Requestor" 
            ? taxReviews.filter(taxReview => taxReview.status.toLowerCase() === pageStatus && taxReview.submittedBy === auth.name) 
            : auth.accessRole === "Supervisor"
                ? taxReviews.filter(taxReview => taxReview.status.toLowerCase() === pageStatus && auth.companies.includes(taxReview._id.split("-")[0]))
                : taxReviews.filter(taxReview => taxReview.status.toLowerCase() === pageStatus);
            
        setReviewList(filter)
        setVisible(false)
    }, [props, taxReviews])

    const closeData = () => {
        setVisible(false)
        setRecord(null)
    }

    const onSearch = values => {
        const searchValue = values.toLowerCase()
        const filter = taxReviews.filter(user => Object.values(user).some(val => typeof val === "string" && val.toLowerCase().includes(searchValue)));
        setReviewList(filter)
    }

    return (
        <div style={{ background: 'white', padding: '24px' }}>
            <Title level={4}>{`Tax Review List - ${capPage}`}</Title>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div style={{ width: '200px' }}><Search onSearch={onSearch} allowClear/></div>
            </div>
            <br />
            <Table 
                dataSource={reviewList} 
                columns={columns}
                onRow={(data) => {
                    return {
                        onClick: event => {
                            history.push(`/tax_review_list/${props.match.params?.status}/${data._id}`, { data: data, close: closeData(), reviewStatus: props.match.params?.status })
                        }
                    }
                }}
                footer={() => (<span style={{ fontStyle: "italic", fontSize: "10" }}>NOTE: IDs marked with <span style={{ color: "red" }}>Red</span> indicates Late Submission.</span>)}
            />
        </div>
    )
}

export default TaxReviewList
