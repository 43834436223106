import React, { useState, useEffect } from 'react'
import { Typography, Table, Row, Col, Button, Input, Space, Popconfirm } from 'antd'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import firebase from '../../firebaseConfig'
import UserHeader from './UserHeader'

const collection = firebase.firestore().collection("users")
const { Title, Link } = Typography
const { Search } = Input

function Users() {
    const users = useSelector(state => state.users)
    const auth = useSelector(state => state.auth)
    const [record, setRecord] = useState(null)
    const [action, setAction] = useState(null) // create, read, update, delete
    const [userList, setUserList] = useState(users)

    const columns =[
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "20%"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "20%"
        },
        {
            title: "Immediate Supervisor",
            dataIndex: "supervisor",
            key: "supervisor",
            width: "20%"
        },
        {
            title: "Supervisor's Email",
            dataIndex: "supervisorEmail",
            key: "supervisorEmail",
            width: "20%"
        },
        {
            title: "Access Role",
            dataIndex: "accessRole",
            key: "accessRole",
            width: "10%"
        },
        {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, record) => {
                return(<Space>
                    <Link onClick={() => {
                        setRecord(record)
                        addUser('Update', record)
                    }}>Edit</Link>
                    { 
                        record.taxSubmitted > 0 
                            ? record.isDisabled 
                                ? <Link onClick={() => enableUser(record)}>Enable</Link> 
                                : <Link onClick={() => disableUser(record)}>Disable</Link>
                            : <Link onClick={() => deleteData(record)}>Delete</Link> 
                    }
                </Space>)
            },
            width: "10%"
        }
    ]

    useEffect(() => {
        setUserList(users)
    }, [users])

    const enableUser = record => {
        console.log(record)
        Swal.fire({
            title: "Enable User",
            text: `Are you sure you want to enable user ${record.name}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection
                    .doc(record.email)
                    .set({ 
                        ...record,
                        isDisabled: false
                })
                .then(() => 
                    Swal.fire({ icon: "success"})
                )
            }
        })
    }

    const disableUser = record => {
        console.log(record)
        Swal.fire({
            title: "Disable User",
            text: `Are you sure you want to disable user ${record.name}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection
                    .doc(record.email)
                    .set({ 
                        ...record,
                        isDisabled: true
                })
                .then(() => 
                    Swal.fire({ icon: "success"})
                )
            }
        })
    }

    const deleteData = record => {
        Swal.fire({
            title: "Delete?",
            text: `Are you sure you want to delete user ${record.name}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                // collection.doc(record.email).delete()
                //     .then((_) => {
                //         setAction(null)
                //         Swal.fire({ icon: "success"})
                //     })
                //     .catch(err => console.log(err))
            }
        })
    }

    const addUser = (act, data) => {
        setAction(act)
        setRecord(data)
    }

    const closeHeader = () => {
        setRecord(null)
        setAction(null)
    }

    const onSearch = value => {
        const searchValue = value.toLowerCase()
        const filter = users.filter(user => Object.values(user).some(val => typeof val === "string" && val.toLowerCase().includes(searchValue)));
        setUserList(filter)
    }

    return (
        <div style={{ background: 'white', padding: '24px' }}>
            <Title level={4}>Users Masterlist</Title>
            {
                action === null
                    ? (<Row justify="space-between" align="middle">
                        <Col></Col>
                        <Col>
                            <Space>
                                { auth.accessRole === "System Admin" ? <Button type="primary" onClick={() => addUser("Add")} >Add User</Button> : <></> }
                                <Search placeholder="Search User" onSearch={onSearch} allowClear/>
                            </Space>
                        </Col>
                    </Row>)
                    : (<UserHeader action={action} closeHeader={() => closeHeader()} record={record} setAction={(e) => setAction(e)} />)
            }
            <br />
            <Table columns={columns} dataSource={userList} bordered/>
        </div>
    )
}

export default Users
