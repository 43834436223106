export const SIGNED_IN = 'SIGNED_IN'
export const SIGNED_OUT = 'SIGNED_OUT'

export const ADD_TAX_TYPE = 'ADD_TAX_TYPE'
export const UPDATE_TAX_TYPE = 'UPDATE_TAX_TYPE'
export const REMOVE_TAX_TYPE = 'REMOVE_TAX_TYPE'

export const ADD_COMPANY = 'ADD_COMPANY'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const REMOVE_COMPANY = 'REMOVE_COMPANY'

export const ADD_TAX_REVIEW = 'ADD_TAX_REVIEW'
export const UPDATE_TAX_REVIEW = 'UPDATE_TAX_REVIEW'
export const REMOVE_TAX_REVIEW = 'REMOVE_TAX_REVIEW'

export const ADD_USER = 'ADD_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const REMOVE_USER = 'REMOVE_USER'