import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Layout, Avatar, Space, Dropdown, Menu, Image, Typography } from 'antd'
import { LogoutOutlined, DashboardOutlined, ClusterOutlined, FileAddOutlined, FileExclamationOutlined, FileDoneOutlined, MoneyCollectOutlined, UsergroupAddOutlined } from '@ant-design/icons'
import moment from 'moment';

import mwide from '../assets/images/mwide-sub-base.png'
import firebase from '../firebaseConfig'
import './MainLayout.less'
import { useHistory } from 'react-router-dom';
import { actions } from '../redux';

const { Header, Sider, Content, Footer} = Layout;
const { SubMenu } = Menu;
const { Text } = Typography


function MainLayout({ children }) {
    const auth = useSelector(state => state.auth)
    const [current, setCurrent] = useState("/dashboard")
    const [collapsed, setCollapsed] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch();

    const userMenu = (
        <Menu>
            <Menu.Item key="logout" onClick={() => signOut()}>
                <LogoutOutlined /> Logout
            </Menu.Item>
        </Menu>
    )

    const menuClick = e => {
        history.push(e.key)
        setCurrent(e.key)
    }

    const sideMenu = (
        <Menu onClick={menuClick} defaultSelectedKeys={[current]} mode="inline" defaultOpenKeys={['tax-review-list']}>
            <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
                Dashboard
            </Menu.Item>
            <hr />
            <Menu.Item key="/file_tax_review" icon={<FileAddOutlined />}>Submit Tax Review</Menu.Item>
            <SubMenu key="tax-review-list" title="Tax Review List">
                <Menu.Item key="/tax_review_list/pending" icon={<FileExclamationOutlined />}>Pending</Menu.Item> 
                <Menu.Item key="/tax_review_list/closed" icon={<FileDoneOutlined />}>Closed</Menu.Item>                
            </SubMenu>
            {
                auth.accessRole !== "Requestor"
                    ? <>
                        <hr />
                        <Menu.Item key="/company_masterlist" icon={<ClusterOutlined />}>
                            Companies
                        </Menu.Item>
                        <Menu.Item key="/tax_type_masterlist" icon={<MoneyCollectOutlined />}>
                            Tax Types
                        </Menu.Item>
                        {
                            auth.accessRole === "System Admin"
                                ? <Menu.Item key="/users_masterlist" icon={<UsergroupAddOutlined />}>
                                    Users
                                </Menu.Item>
                                : <></>
                        }
                    </>
                    : <></>
            }
        </Menu>
    )
    
    const signOut = () => {
        firebase.auth().signOut()
            .then(result => {
                dispatch(actions.signedOut())
                history.push("/login")
                localStorage.clear()
                window.location.reload()
            })
            .catch(err => console.log(err))
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header className="main-layout-header">
                <Text style={{ color: 'white' }}>Welcome</Text>
                <Dropdown overlay={userMenu} placement="bottomRight" arrow>
                    <Space>
                        {auth.name}
                        <Avatar src={auth.avatar}/>
                    </Space>
                </Dropdown>
            </Header>
            <Layout className="main-layout">
                <Sider className="main-layout-sider" width={250}>
                    <div className="sider-logo" style={{ width: '100%', padding: '8px'}}>
                        <Image src={mwide} preview={false} />
                    </div>
                    <hr />
                    {sideMenu}
                </Sider>
                <Content className="main-layout-content" >{children}</Content>
            </Layout>
            <Footer className="main-layout-footer">Tax Return Review Portal © {moment().year()} Megawide Construction Corporation</Footer>
        </Layout>
    )
}

export default MainLayout
