import React, { useState, useEffect } from 'react'
import { Form, Input, Select, Row, Col, Upload, Button, message, Typography } from 'antd'
import { UploadOutlined, InboxOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'

import firebase from '../../firebaseConfig'
import moment from 'moment'
import Swal from 'sweetalert2'

const storageRef = firebase.storage().ref();
const db = firebase.firestore()

const { Option } = Select
const { Dragger } = Upload
const { Title } = Typography

const taxablePeriodList = [
    "31/01/2021",
    "28/02/2021",
    "31/03/2021",
    "30/04/2021",
    "31/05/2021",
    "30/06/2021",
    "31/07/2021",
    "31/08/2021",
    "30/09/2021",
    "31/10/2021",
    "30/11/2021",
    "31/12/2021"
]

const documentMapping = {
    "EWT": [
        {name: "attach1", label: "Draft BIR form 0619-E"},
        {name: "attach2", label: "EWT Breakdown"},
        {name: "attach3", label: "Alphalist of Payees"}
    ],
    "FWT": [
        {name: "attach1", label: "Draft BIR form 0619-F"},
        {name: "attach2", label: "FWT Breakdown"},
        {name: "attach3", label: "Alphalist of Payees"}
    ],
    "WTC": [
        {name: "attach1", label: "Draft BIR form 0619-C"},
        {name: "attach2", label: "WTC Supporting Computation"}
    ],
    "WVAT": [
        {name: "attach1", label: "Draft BIR form 1600"},
        {name: "attach2", label: "Alphalist of Payees"},
        {name: "attach3", label: "WVAT Supporting Computation"}
    ],
    "VAT": [
        {name: "attach1", label: "Draft BIR form 2550M"},
        {name: "attach2", label: "VAT Computation"},
        {name: "attach3", label: "Summary List of Sales, Purchases and Importation"}
    ],
    "FBTQ": [
        {name: "attach1", label: "Draft BIR form 1603Q"},
        {name: "attach2", label: "FBT Breakdown"}
    ],
    "EWTQ": [
        {name: "attach1", label: "Draft BIR form 1601EQ"},
        {name: "attach2", label: "EWT Breakdown"},
        {name: "attach3", label: "Alphalist of Payees"}
    ],
    "FWTQ": [
        {name: "attach1", label: "Draft BIR form 1601FQ"},
        {name: "attach2", label: "FWT Breakdown"},
        {name: "attach3", label: "Alphalist of Payees"}
    ],
    "VATQ": [
        {name: "attach1", label: "Draft BIR form 2550Q"},
        {name: "attach2", label: "VAT Computation"},
        {name: "attach3", label: "Summary List of Sales, Purchases and Importation"}
    ],
    "ITQ": [
        {name: "attach1", label: "Draft BIR form 1702Q"},
        {name: "attach2", label: "Income Tax Computation and Reconciliation"},
        {name: "attach3", label: "Summary Alphalist of Withholding Tax at Source"}
    ],
    "PT": [
        {name: "attach1", label: "Draft BIR form 2551Q"},
        {name: "attach2", label: "PT Supporting Computation"}
    ],
    "IT": [
        {name: "attach1", label: "Draft BIR form 1702"},
        {name: "attach2", label: "Income Tax Computation and Reconciliation"},
        {name: "attach3", label: "Summary Alphalist of Withholding Tax at Source"},
        {name: "attach4", label: "Draft AFS"},
        {name: "attach5", label: "Copy of Special Registration (if any)", required: false},
        {name: "attach6", label: "Copy of ITH Entitlement (if any)", required: false}
    ]
}

const date = moment()
const start = 0;
const end = 12;
const monthOptions = [];

const current = date.clone();
const localeData = date.localeData();
const months = [];
for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
}

for (let index = start; index < end; index++) {
    monthOptions.push(
        <Option className="month-item" key={index}>
        {months[index]}
        </Option>,
    );
}

const mnth = date.month()
const yr = date.year()
const options = []

for (let i = yr - 5; i < yr + 5; i += 1) {
    options.push(
        <Option key={i} value={i} className="year-item">
        {i}
        </Option>,
    );
}
function Index({ location }) {
    const auth = useSelector(state => state.auth)
    const [form] = Form.useForm()
    const [record, setRecord] = useState(null)
    const companies = useSelector(state => state.companies)
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [taxType, setTaxType] = useState(location.state?.tax || null)
    const [month, setMonth] = useState(null)
    const [year, setYear] = useState(null)

    const userCompany = auth.accessRole === "Requestor" 
        ? companies.filter(comp => comp.email === auth.email) 
        : auth.accessRole === "Supervisor"
            ? companies.filter(compa => auth.companies.includes(compa.shortName))
            : companies

    useEffect(() => {
        selectRecord(location.state?.company)
        taxTypeChange(location.state?.tax)
        form.setFieldsValue({company: location.state?.company, taxType: location.state?.tax})
    }, [companies])

    const selectRecord = value => {
        const data = companies.filter(company => company.companyName === value)
        form.setFieldsValue({...data[0]})
        setRecord(data[0])
    }

    const onFinish = async values => {
        const taxReview = { ...values }
        const doc_id = `${record?.shortName}-${record?.taxReviews.toString().padStart(5, '0')}`
        const fileListDTO = []
        
        fileListDTO.push(taxReview.files.trialBalance.file)
        fileListDTO.push(taxReview.files.generalLedger.file)
        fileListDTO.push(taxReview.files.attach1?.file)
        fileListDTO.push(taxReview.files.attach2?.file)
        fileListDTO.push(taxReview.files.attach3?.file)
        fileListDTO.push(taxReview.files.attach4?.file)
        fileListDTO.push(taxReview.files.attach5?.file)
        fileListDTO.push(taxReview.files.attach6?.file)
        
        taxReview.files?.others?.fileList?.map(file => fileListDTO.push(file.originFileObj))
        
        // console.log(fileListDTO.filter(file => file !== undefined))
        const request = fileListDTO.filter(file => file !== undefined).map( async file => {
            if(typeof file !== "undefined") {
                const taxReviewRef = storageRef.child(`taxReviews/${doc_id}/${file?.name}`)
                const response = await taxReviewRef.put(file)
                return response.metadata.fullPath;
            }
        })

        Promise.all(request)
        .then(async result => {
            message.success("Files Uploaded Successfully")
            console.log(result)
            delete taxReview.files
            taxReview.taxablePeriod = moment().month(values.month).year(values.year).format("MMM YYYY")
            taxReview.isLate = month && year && moment().month(month).year(year).isBefore(date, "month") ? true : false
            taxReview.initialFiles = result
            taxReview.additionalFiles = []
            taxReview._id = doc_id
            taxReview.dateSubmitted = firebase.firestore.Timestamp.fromDate(new Date())
            taxReview.status = "Pending"
            delete taxReview.month
            delete taxReview.year
            
            db.collection('taxReviews').doc(doc_id).set(taxReview)
                .then(() => message.success("Tax Review Submitted Successfully"))
                .catch(() => console.log("failed"))
        })
        .then(() => {
            db.collection("companies").doc(record.shortName).update({
                taxReviews: firebase.firestore.FieldValue.increment(1)
            })
            .then(() => console.log("Done"))
        })
        .then(() => {
            db.collection("users").doc(auth.email).update({
                taxSubmitted: firebase.firestore.FieldValue.increment(1)
            })
            .then(() => console.log("Done"))
        })
    }

    const taxTypeChange = value => {
        setTaxType(value)
    }


    return (
        <div style={{ background: 'white', padding: '24px' }}>
            <Title level={4}>File Tax Review</Title>
            <Form
                form={form}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 10 }}
                onFinish={(values) => {
                    Swal.fire({
                        title: "Late Submission for Tax Review",
                        text: "Uploaded documents will be subjected to post review. To avoid penalties due to late filing, you may proceed with tax filing and payment thru eBIRform or eFPS, whichever is applicable.",
                        icon: "warning",
                        confirmButtonText: "Confirm",
                        showCancelButton: true
                    })
                    .then((res) => {
                        if(res.isConfirmed) {
                            onFinish(values)
                        }
                    })
                }}
                initialValues={{
                    submittedBy: auth.name,
                    approvedBy: auth.supervisor
                }}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Company"
                            name="company"
                            rules={[{ required: true, message: "Please select Company" }]}
                        >
                            <Select onSelect={(e) => selectRecord(e)} showSearch value={selectedCompany}>
                                {
                                    userCompany.map(company => (<Option value={company.companyName}>{company.companyName}</Option>))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Mode of Submission"
                            name="formType"
                        >
                            <Input value={record?.formType} readOnly />
                        </Form.Item>
                        <Form.Item
                            label="Group Type"
                            name="groupType"
                        >
                            <Input value={record?.groupType} readOnly />
                        </Form.Item>
                        <Form.Item
                            label="Tax Type"
                            name="taxType"
                            rules={[{ required: true, message: "Please select tax type" }]}
                        >
                            <Select onChange={taxTypeChange}>
                                {
                                    record?.taxTypes?.map(tax => (<Option value={tax}>{tax}</Option>))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Taxable Period"
                            name="taxablePeriod"
                        >
                            <Form.Item
                                name="month"
                                rules={[{ required: true, message: "Please select month" }]}
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)'}}
                            >
                                <Select
                                    onChange={selectedMonth => {
                                        setMonth(selectedMonth)
                                    }}
                                >
                                    { monthOptions }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="year"
                                rules={[{ required: true, message: "Please select year" }]}
                                style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 0 0 16px' }}
                            >
                                <Select
                                    onChange={selectedYear => {
                                        setYear(selectedYear)
                                    }}
                                >
                                    { options }
                                </Select>
                            </Form.Item>
                            <span style={{color: "red"}} >{ month && year && moment().month(month).year(year).isBefore(date, "month") ? "This will be marked as Late Submission" : ""}</span>
                        </Form.Item>
                        <Form.Item
                            label="Submitted By"
                            name="submittedBy"
                            rules={[{ required: true, message: "Please enter submitter" }]}
                        >
                            <Input readOnly/>
                        </Form.Item>
                        <Form.Item
                            label="Approved By / Immediate Supervisor"
                            name="approvedBy"
                            rules={[{ required: true, message: "Please enter approver / immediate supervisor" }]}
                        >
                            <Input readOnly/>
                        </Form.Item>
                        <Form.Item
                             wrapperCol={{ offset: 10, span: 14 }}
                        >
                            <Button htmlType="submit" type="primary">Submit</Button>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        Attachments:
                        <Form.Item
                            label="Trial Balance for the Month"
                            name={["files", "trialBalance"]}
                            rules={[{ required: true, message: "Please select trial balance file" }]}
                        >
                            <Upload
                                beforeUpload={file => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="General Ledger for the Month"
                            name={["files", "generalLedger"]}
                            rules={[{ required: true, message: "Please select general ledger file" }]}
                        >
                            <Upload
                                beforeUpload={file => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                        Additional Documents:
                        {
                            typeof documentMapping[taxType] === "undefined"
                                ? (<></>)
                                : documentMapping[taxType].map(doc => {
                                    return (<Form.Item
                                        label={doc.label}
                                        name={["files", doc.name]}
                                        rules={[{ required: doc.required ?? true, message: `Please select ${doc.label}` }]}
                                    >
                                        <Upload
                                            beforeUpload={file => false}
                                            maxCount={1}
                                        >
                                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                        </Upload>
                                    </Form.Item>)
                                })
                        }
                        <Form.Item
                            label="Others"
                            name={["files", "others"]}
                        >
                            <Dragger
                                beforeUpload={() => false}
                                multiple
                            >
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                <p className="ant-upload-hint">
                                    Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                    band files
                                </p>
                            </Dragger>   
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default Index
