import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Space, Button, Select } from 'antd'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import './index.less'
import firebase from '../../firebaseConfig'

const { Option } = Select;
const collection = firebase.firestore().collection("companies")

function CompanyHeader({ closeHeader, record, action, setAction }) {
    const taxTypes = useSelector(state => state.taxTypes)
    const users = useSelector(state => state.users)
    const auth = useSelector(state => state.auth)
    const [form] = Form.useForm()
    const [disable, setDisable] = useState(true)
    const [initialState, setInitialState] = useState(record)
    const [owner, setOwner] = useState(null)
    const [groupType, setGroupType] = useState(null)

    useEffect(() => {
        if(record) {
            setInitialState(record)
            form.setFieldsValue(record)
            setGroupType(record.groupType)
        } else {
            setDisable(false)
        }
    }, [record])

    const ActionButtons = () => {
        switch(action) {
            case "Add":
                return (
                    <Space>
                        <Button type="primary" onClick={() => form.submit()}>Add</Button>
                        <Button onClick={() => closeHeader()} >Cancel</Button>
                    </Space>
                )
            case "View":
                return (
                    <Space>
                        {   
                            auth.accessRole === "System Admin"
                            ? <>
                                <Button onClick={() => {setDisable(false); setAction("Update")}} type="primary">Edit</Button>
                                { 
                                    record?.taxReviews > 1 
                                        ? record?.isInactive
                                            ? <Button onClick={() => setActiveCompany(record)}>Set Active</Button>
                                            : <Button onClick={() => setInactiveCompany(record)}>Set Inactive</Button>
                                        : <Button onClick={() => deleteData(record)}>Delete</Button> 
                                }
                            </>
                            : <></>
                        }
                        <Button onClick={() => closeHeader()} >Close</Button>
                    </Space>
                )
            case "Update":
                return (
                    <Space>
                        <Button onClick={() => form.submit()} type="primary">Save</Button>
                        <Button onClick={() => {setDisable(true); setAction("View"); form.resetFields()}} >Cancel</Button>
                    </Space>
                )
        }
    }

    const setActiveCompany = record => {
        console.log(record)
        Swal.fire({
            title: "Set Inactive Company",
            text: `Are you sure you want to mark ${record.companyName} as Active?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection
                    .doc(record.shortName)
                    .set({ 
                        ...record,
                        isInactive: false
                        
                })
                .then(() => 
                    Swal.fire({ icon: "success"})
                        .then(() => closeHeader())
                )
            }
        })
    }

    const setInactiveCompany = record => {
        console.log(record)
        Swal.fire({
            title: "Set Inactive Company",
            text: `Are you sure you want to mark ${record.companyName} as Inactive?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection
                    .doc(record.shortName)
                    .set({ 
                        ...record,
                        isInactive: true
                        
                })
                .then(() => 
                    Swal.fire({ icon: "success"})
                        .then(() => closeHeader())
                )
            }
        })
    }

    const deleteData = record => {  
        Swal.fire({
            title: "Delete?",
            text: `Are you sure you want to delete ${record.companyName}?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                collection.doc(record.shortName).delete()
                    .then((_) => {
                        setDisable(true)
                        setAction(null)
                        Swal.fire({ icon: "success"})
                            .then(() => closeHeader())
                    })
                    .catch(err => console.log(err))
            }
        })
    }

    const onFinish = data => {
        
        if(action === "Add") {
            const record = { ...data, taxReviews: 1 }
            collection.doc(data.shortName).set(record)
                .then(() => {
                    setDisable(true)
                    if(action === "Add") {
                        setAction(null)
                    } else {
                        setAction("View")
                    }
                })
                .catch(err => console.log(err))
        } else {
            collection.doc(data.shortName).update(data)
                .then(() => {
                    setDisable(true)
                    if(action === "Add") {
                        setAction(null)
                    } else {
                        setAction("View")
                    }
                })
                .catch(err => console.log(err))
        }
    }


    const onChange = value => {
        setGroupType(value)
    }

    const onSelect = value => {
        const user = users.find(user => user.name === value)
        form.setFieldsValue({...user})
        setOwner(user)
    }

    return (
        <div className="company-header-container">
            <div className="action-bar">
                <div className="action-header">{record === undefined ? "Add Company" : record?.companyName}</div>
                <div className="action-buttons">
                    <ActionButtons />
                </div>
            </div>
            <br />
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                initialValues={initialState}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Form Type"
                            name="formType"
                            rules={[{ required: true, message: "Please select form type" }]}
                        >
                            <Select showSearch onChange={onChange} disabled={disable}>
                                <Option value="eBIRForm">eBIRForm</Option>
                                <Option value="eFPS">eFPS</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Group Type"
                            name="groupType"
                            rules={[{ required: true, message: "Please select group type" }]}
                        >
                            {
                                groupType === "eBIRForm"
                                    ? (<Select showSearch disabled={disable}> 
                                        <Option value="eBIRForm">eBIRForm</Option>
                                    </Select>)
                                    : (<Select showSearch disabled={disable}>
                                        <Option value="Group A">Group A</Option>
                                        <Option value="Group B">Group B</Option>
                                        <Option value="Group C">Group C</Option>
                                        <Option value="Group D">Group D</Option>
                                        <Option value="Group E">Group E</Option>
                                    </Select>)
                            }
                        </Form.Item>
                        <Form.Item
                            label="Short Name"
                            name="shortName"
                            rules={[{ required: true, message: "Please enter short name" }]}
                        >
                            <Input readOnly={disable}/>
                        </Form.Item>
                        <Form.Item
                            label="Company Name"
                            name="companyName"
                            rules={[{ required: true, message: "Please enter company name" }]}
                        >
                            <Input readOnly={disable}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Process Owner"
                            name="processOwner"
                            rules={[{ required: true, message: "Please enter process owner" }]}
                        >
                            <Select onSelect={(e) => onSelect(e)} showSearch disabled={disable}>
                                {
                                    users.map(user => (<Option value={user.name}>{user.name}</Option>))
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Email Address"
                            name="email"
                        >
                            <Input value={owner?.email} readOnly />
                        </Form.Item>
                        <Form.Item
                            label="Tax Types"
                            name="taxTypes"
                            rules={[{ required: true, message: "Please select tax type/s" }]}
                        >
                            <Select mode="multiple" disabled={disable}>
                                {
                                    taxTypes.map(taxType => {
                                        return(<Option value={taxType.taxCode}>{taxType.taxName}</Option>)
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CompanyHeader
