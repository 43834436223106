import React, { useEffect } from 'react'
import { Row, Col, Table, Button, Input, Typography } from 'antd'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import TaxHeader from './TaxHeader'

const { Search } = Input
const { Column, ColumnGroup } = Table
const { Title } = Typography

function TaxType() {
    const taxTypes = useSelector(state => state.taxTypes)
    const auth = useSelector(state => state.auth)
    const [record, setRecord] = useState(null)
    const [action, setAction] = useState(null)
    const [taxTypeList, setTaxTypeList] = useState(taxTypes)

    useEffect(() => {
        setTaxTypeList(taxTypes)
    }, [taxTypes])

    const addTaxType = (act, data) => {
        setAction(act)
        setRecord(data)
    }

    const closeModal = () => {
        setRecord(null)
        setAction(null)
    }
    
    const onSearch = values => {
        const searchValue = values.toLowerCase()
        const filter = taxTypes.filter(user => Object.values(user).some(val => typeof val === "string" && val.toLowerCase().includes(searchValue)));
        setTaxTypeList(filter)
    }

    return (
        <div style={{ background: 'white', padding: '24px' }}>
            <Title level={4}>Tax Types Masterlist</Title>
            {
                action === null 
                    ? (<Row justify="end" align="middle" gutter={8}>
                        { auth.accessRole === "System Admin" ? <Col><Button onClick={() => addTaxType('Add')} type="primary">Add Tax Type</Button></Col> : <></>}
                        <Col><Search onSearch={onSearch} allowClear/></Col>
                    </Row>)
                    : (<TaxHeader action={action} closeHeader={() => closeModal()} record={record} setAction={(e) => setAction(e)} />)
            }
            <br />
            <Table 
                style={{ cursor: 'pointer' }}
                dataSource={taxTypeList} 
                bordered 
                pagination={{
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                }} 
                onRow={(data) => {
                    return {
                        onClick: event => {
                            setRecord(data)
                            addTaxType('View', data)
                        }
                    }
                }}
            >
                <Column 
                    title="Tax Code"
                    dataIndex="taxCode"
                    key="taxCode"
                    width="10%"
                />
                <Column 
                    title="Tax Name"
                    dataIndex="taxName"
                    key="taxName"
                    width="20%"
                />
                <Column 
                    title="Frequency"
                    dataIndex="frequency"
                    key="frequency"
                    width="10%"
                />
                <Column 
                    title="eBIR Deadline"
                    dataIndex="birDeadline"
                    key="eBIRform"
                    width="10%"
                    render={(_, record) => (<span>{record.birDeadline.eBIRform}</span>)}
                />
                <ColumnGroup title="eFPS Deadline">
                    <Column 
                        title="Group A"
                        dataIndex="birDeadline.eFPS.groupA"
                        key="groupA"
                        width="6%"
                        render={(_, record) => (<span>{record.birDeadline.eFPS.groupA}</span>)}
                    />
                    <Column 
                        title="Group B"
                        dataIndex="birDeadline.eFPS.groupB"
                        key="groupA"
                        width="6%"
                        render={(_, record) => (<span>{record.birDeadline.eFPS.groupB}</span>)}
                    />
                    <Column 
                        title="Group C"
                        dataIndex="birDeadline.eFPS.groupC"
                        key="groupA"
                        width="6%"
                        render={(_, record) => (<span>{record.birDeadline.eFPS.groupC}</span>)}
                    />
                    <Column 
                        title="Group D"
                        dataIndex="birDeadline.eFPS.groupD"
                        key="groupA"
                        width="6%"
                        render={(_, record) => (<span>{record.birDeadline.eFPS.groupD}</span>)}
                    />
                    <Column 
                        title="Group E"
                        dataIndex="birDeadline.eFPS.groupE"
                        key="groupA"
                        width="6%"
                        render={(_, record) => (<span>{record.birDeadline.eFPS.groupE}</span>)}
                    />
                </ColumnGroup>
                <Column 
                    title="Tax Team Deadline"
                    dataIndex="taxTeamDeadline"
                    key="taxTeamDeadline"
                    width="5%"
                />
                <Column 
                    title="Submission of Additional Attachments Deadline"
                    dataIndex="attachmentDeadline"
                    key="attachmentDeadline"
                    width="5%"
                />
            </Table>
        </div>
    )
}

export default TaxType
