import React, { useState } from 'react'
import { Calendar, Badge, Row, Col, Typography, Card, Select, Radio, Form, DatePicker, Button, Table, Empty } from 'antd'
import { useSelector } from 'react-redux';
import { useHistory, Link as LinkRouter } from 'react-router-dom'
import { ExpandOutlined, CompressOutlined } from '@ant-design/icons'
import moment from 'moment'
import axios from 'axios';

import { sendMail } from '../../services/mailer/sendMail';
import { wTaxMonthlyTemplate } from '../../services/mailer/emailTemplates';
import './index.less'

const { Link, Title } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

const m8 = [1, 2, 4, 5, 7, 8, 10, 11]
const qtr4 = [0, 3, 6, 9]
const qtr3 = [2, 5, 8]

const columns = [
    {
        title: 'Period',
        dataIndex: 'taxablePeriod',
        key: 'taxablePeriod'
    },
    {
        title: 'Date Submitted',
        dataIndex: 'dateSubmitted',
        key: 'dateSubmitted',
        render: (text) => {
            return(moment(text.toDate()).format("MMM DD, YYYY"))
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status'
    },
    {
        title: 'Date Closed',
        dataIndex: 'dateClosed',
        key: 'dateClosed',
        render: (text) => {
            return(moment(text?.toDate()).format("MMM DD, YYYY"))
        }
    },
    {
        title: 'View Review Report',
        dataIndex: 'reportLink',
        key: 'reportLink',
        render: (text, record) => {
            return(<LinkRouter to={{
                pathname: `/tax_review_list/pending/${record._id}`,
                state: {
                    data: record,
                    reviewStatus: record.status === "Closed" ? "closed" : "pending"
                }
            }}>View</LinkRouter>)
        }
    },
    {
        title: 'View Filed Tax Return',
        dataIndex: 'filedTaxReturn',
        key: 'filedTaxReturn'
    }
]

function Dashboard() {
    const auth = useSelector(state => state.auth)
    const [form] = Form.useForm()
    const history = useHistory()
    const companies = useSelector(state => state.companies)
    const taxTypes = useSelector(state => state.taxTypes)
    const taxReviews = useSelector(state => state.taxReviews)
    const users = useSelector(state => state.users)
    const [selectCompany, setSelectCompany] = useState(null)
    const [fullscreen, setFullscreen] = useState(false)
    const [summary, setSummary] = useState([])
    const userCompany = auth.accessRole === "Requestor" 
        ? companies.filter(comp => comp.email === auth.email) 
        : auth.accessRole === "Supervisor"
            ? companies.filter(compa => auth.companies.includes(compa.shortName))
            : companies

    const createList = (frequency, value, company, tax, deadline, currDate, listData) => {
        if(frequency === "Annual"){
            if(value.month() === 3) {
                if(value.date() === 15) {
                    if(moment().isAfter(value)) {
                        listData.push({ type: 'error', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else if(moment().diff(value, 'days') <= 5 && moment().diff(value, 'days') >= 0) {
                        listData.push({ type: 'warning', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else {
                        listData.push({ type: 'success', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    }
                }
            }
        } else if(frequency === "Quarterly (4)") {
            const from25 = deadline === "" ? moment(currDate).subtract(currDate
                .endOf('month').date(), 'days') : moment(currDate).subtract(deadline, 'days')
            const endOfQtr = moment(new Date(from25.year(), from25.month())).endOf('quarter')
            const sameMonth = qtr3.includes(from25.month())
            const sameDay = from25.isSame(endOfQtr, 'day')

            if(qtr4.includes(value.month())) {
                if(sameDay) {
                    if(moment().isAfter(value)) {
                        listData.push({ type: 'error', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else if(moment().diff(value, 'days') <= 5 && moment().diff(value, 'days') >= 0) {
                        listData.push({ type: 'warning', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else {
                        listData.push({ type: 'success', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    }
                }
            }
        } else if(frequency === "Quarterly (3)") {
            const from60 = moment(currDate).subtract(60, 'days')
            const endOfQtr = moment(new Date(from60.year(), from60.month())).endOf('quarter')
            const sameMonth = qtr3.includes(from60.month())
            const sameDay = from60.isSame(endOfQtr, 'day')
            
            if(qtr3.includes(from60.month())) {
                if(sameMonth && sameDay) {
                    if(moment().isAfter(value)) {
                        listData.push({ type: 'error', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else if(moment().diff(value, 'days') <= 5 && moment().diff(value, 'days') >= 0) {
                        listData.push({ type: 'warning', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else {
                        listData.push({ type: 'success', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    }
                }
            }
        } else if(frequency === "Monthly (8)") {
            const from10 = moment(currDate).subtract(deadline, 'days')
            const endOfMonth = moment(new Date(from10.year(), from10.month())).endOf('month')
            const sameMonth = qtr3.includes(from10.month())
            const sameDay = from10.isSame(endOfMonth, 'day')

            if(m8.includes(value.month())) {
                if(sameDay) {
                    if(moment().isAfter(value)) {
                        listData.push({ type: 'error', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else if(moment().diff(value, 'days') <= 5 && moment().diff(value, 'days') >= 0) {
                        listData.push({ type: 'warning', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    } else {
                        listData.push({ type: 'success', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                    }
                }
            }
        } else {
            if(parseInt(deadline) === value.date()) {
                if(moment().isAfter(value)) {
                    listData.push({ type: 'error', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                } else if(moment().diff(value, 'days') <= 5 && moment().diff(value, 'days') > 0) {
                    listData.push({ type: 'warning', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                } else {
                    listData.push({ type: 'success', content: `${company.shortName} - ${tax.taxCode}`, company: company.companyName, taxType: tax.taxCode})
                }
            }
        }
        // return listData;
    }

    const computeDeadline = (value) => {
        const listData = [];
        let tax;
        let period;

        userCompany.map(company => {
            console.log(company)
            company.taxTypes.map(taxType => {
                const tax = taxTypes.find(type => type.taxCode === taxType)
                const currDate = moment(value)
                let deadline
                switch(company.groupType) {
                    case "Group A":
                        deadline = tax.birDeadline?.eFPS?.groupA
                        createList(tax.frequency, value, company, tax, tax.birDeadline?.eFPS?.groupA, currDate, listData)
                        break;
                    case "Group B":
                        createList(tax.frequency, value, company, tax, tax.birDeadline?.eFPS?.groupB, currDate, listData)
                        break;
                    case "Group C":
                        createList(tax.frequency, value, company, tax, tax.birDeadline?.eFPS?.groupC, currDate, listData)
                        break;
                    case "Group D":
                        createList(tax.frequency, value, company, tax, tax.birDeadline?.eFPS?.groupD, currDate, listData)
                        break;
                    case "Group E":
                        createList(tax.frequency, value, company, tax, tax.birDeadline?.eFPS?.groupE, currDate, listData)
                        break;
                    default:
                        createList(tax.frequency, value, company, tax, tax.birDeadline?.eBIRform, currDate, listData)
                        break;
                }
            })
        })
        return listData || [];
    }

    const getListData = (value) => {
        let listData = computeDeadline(value);
        const currentDate = moment().date()
        
        return listData || [];
    }

    const dateCellRender = (value) => {
        const listData = getListData(value);

        const plus5day = moment().add(5, "d")
        if(plus5day.isSame(value, "day")) {
            handleSendEmail(listData)
            console.log(listData)
        }

        return (
            <ul className="events" style={{ listStyle: "none" }}>
                {listData.map((item, index) => {
                    return item?.type === "error"
                        ? (
                            <li key={index}>
                                <Badge status={item?.type} text={item?.content} />
                            </li>
                        ) : (
                            <li key={index}>
                                <Badge status={item?.type} text={<Link underline onClick={() => {
                                    history.push("/file_tax_review", { company: item?.company, tax: item?.taxType })
                                }}>{item?.content}</Link>} />
                            </li>
                        )
                })}
            </ul>
        );
        
    }

    const onSelectCompany = value => {
        const res = companies.find(company => company.companyName === value)
        setSelectCompany(res)
    }

    const onFinish = values => {
        console.log(values)

        const result = taxReviews.filter(taxReview => taxReview.company === values.company)
        setSummary(result)

        if(values.taxType) {
            const resultWithTax = result.filter(taxReview => taxReview.taxType === values.taxType)
            setSummary(resultWithTax)
        }
    }

    const handleSendEmail = (dates) => {
        const list = dates.map(date => {
            const month = moment().format("MMMM")
            const year = moment().format("YYYY")

            const companyName = date.content.split(" ")[0]

            const mailCompany = companies.find(company => company.shortName === companyName)
            console.log(mailCompany)

            const supervisor = users.find(user => user.email === mailCompany.email)

            // sendMail({emailTo: mailCompany.email, subject: `${mailCompany.shortName} BIR Filing ${month} ${year} Remittance of Withholding Taxes`, cc: supervisor.supervisorEmail, template })
        })
    }

    const template = (<>
        <p>Dear Vegilla, Kelvin,</p>
        <p>We wish to remind you that the filing and remittance of Income Tax for the year of 2021 are due on the following dates:</p>
        <table border='1'>
            <tr>
                <td>Company Name:</td>
                <td>companyName</td>
            </tr>
            <tr>
                <td>Mode of Filing:</td>
                <td>eBIRForm</td>
            </tr>
            <tr>
                <td>Group Type:</td>
                <td>eBIRForm</td>
            </tr>
            <tr>
                <td>Tax Type:</td>
                <td>IT</td>
            </tr>
            <tr>
                <td>Deadlines:</td>
                <td></td>
            </tr>
            <tr>
                <td>Filing and Payment to BIR:</td>
                <td>15-Apr-22</td>
            </tr>
            <tr>
                <td>Submission of Required Attachments to eSubmission:</td>
                <td>15-Apr-22</td>
            </tr>
            <tr>
                <td>Submission to Tax Team:</td>
                <td>10-Apr-22</td>
            </tr>
        </table>
        <p></p>
        <p>In this regard, kindly submit the returns for review prior to filing with the BIR. The following documents will be needed in our review: </p>
        <ol>
            <li>WTB and GL for the year 2021 </li>
            <li>Draft BIR Form 1702</li> 
            <li>Income Tax Computation & Reconciliation (Tax Template) </li>
            <li>CWT certificates claimed (BIR Form 2307) </li>
            <li>Summary Alphalist of Withholding Tax at Source (SAWT) in. dat file </li>
            <li>Ajusting journal entries (CAJE & PAJE) </li>
            <li>Copy of special registrations and certificate of ITH entitlement </li>
            <li>Schedule of interest income </li>
            <li>Schedule of other income </li>
            <li> Schedule of COC / COS </li>
            <li>Schedule of provisions and accruals </li>
            <li>Depreciation schedule </li>
            <li>BIR approved retirement plan </li>
            <li>If entitled to ITH incentives, schedule and basis of allocation to registered & non-registered activity </li>
        </ol>
        <p>For Notes to FS Disclosure: </p>
        <ol>
            <li>Draft AFS </li>
            <li>Filed BIR Forms 1601 EQ, 1601FQ, 1603Q, and 2550Q, if none submitted yet. </li>
        </ol>
        <p>The above mentioned due dates are based on the information provided to us. Kindly advise us for any changes in BIR 2303, mode of filing and group type (for eFPS filers).</p>
        <p>If you have clarifications please let us know. Thank you!</p>
    </>)

    return (
        <div style={{ padding: "24px", background: "white" }}>
            {
                auth.accessRole === "Requestor" || auth.accessRole === "Supervisor"
                    ? <></>
                    : <>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Card className="card-hover">
                                    <div className="card-chartTop">
                                        <div className="card-metaWrap">
                                            <div className="card-index-meta">
                                                <span>Total Companies</span>
                                            </div>
                                            <div className="card-index-total">{companies.length}</div>
                                        </div>
                                    </div>
                                    <div className="card-index-footer">
                                        Most submissions: <span style={{ fontWeight: 'bold' }}>{companies.length > 0 ? companies?.reduce((a, b) => { return a.taxReviews > b.taxReviews ? a : b }).companyName : 0}</span>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card  className="card-hover">
                                    <div className="card-chartTop">
                                        <div className="card-metaWrap">
                                            <div className="card-index-meta">
                                                <span>Total Tax Reviews</span>
                                            </div>
                                            <div className="card-index-total">{taxReviews.length}</div>
                                        </div>
                                    </div>
                                    <div className="card-index-footer">
                                        <Row>
                                            <Col span={12}>
                                                Pending: {taxReviews.filter(tax => tax.status === "Pending").length} 
                                            </Col>
                                            <Col span={12}>
                                                Closed: {taxReviews.filter(tax => tax.status === "Closed").length}
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card  className="card-hover">
                                    <div className="card-chartTop">
                                        <div className="card-metaWrap">
                                            <div className="card-index-meta">
                                                <span>Total Tax Types</span>
                                            </div>
                                            <div className="card-index-total">{taxTypes.length}</div>
                                        </div>
                                    </div>
                                    <div className="card-index-footer">
                                        &nbsp;
                                    </div>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card  className="card-hover">
                                    <div className="card-chartTop">
                                        <div className="card-metaWrap">
                                            <div className="card-index-meta">
                                                <span>Total Users</span>
                                            </div>
                                            <div className="card-index-total">{users.length}</div>
                                        </div>
                                    </div>
                                    <div className="card-index-footer">
                                        &nbsp;
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <br />
                        <hr />
                        <br />
                    </>
            }
            <Row gutter={[24, 24]}>
                <Col span={fullscreen ? 24 : 18} order={fullscreen ? 2 : 1 }>
                    <Title level={4}>Summary Report</Title>
                    <Form form={form} title={selectCompany?.companyName} layout="inline" onFinish={onFinish}>
                        <Form.Item 
                            label="Company"
                            name="company"
                            rules={[{ required: true, message: 'Please Select Company'}]}
                            >
                            <Select placeholder="Select Company" style={{ width: 250 }} onSelect={onSelectCompany} showSearch>
                                {
                                    userCompany.map(company => <Option value={company.companyName} key={company.shortName}>{company.companyName}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="Tax Type"
                            name="taxType"
                            >
                            <Select placeholder="Select Tax Type" style={{ width: 150 }} showSearch allowClear>
                                {
                                    selectCompany?.taxTypes?.map(ctax => <Option value={ctax}>{ctax}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            label="Taxable Period"
                            name="taxPeriod"
                            >
                            <RangePicker picker="month" />
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">Search</Button>
                        </Form.Item>
                    </Form>
                    <br />
                    <Table 
                        locale={{
                            emptyText: () => <Empty description="Select a Company" image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                        }}
                        columns={columns}
                        dataSource={summary}
                    />
                </Col>
                <Col span={fullscreen ? 24 : 6} order={fullscreen ? 1 : 2 }>
                    <Calendar 
                        dateCellRender={fullscreen ? dateCellRender : null}
                        fullscreen={fullscreen} 
                        headerRender={({ value, type, onChange, onTypeChange }) => {
                            const start = 0;
                            const end = 12;
                            const monthOptions = [];

                            const current = value.clone();
                            const localeData = value.localeData();
                            const months = [];
                            for (let i = 0; i < 12; i++) {
                                current.month(i);
                                months.push(localeData.monthsShort(current));
                            }

                            for (let index = start; index < end; index++) {
                                monthOptions.push(
                                    <Select.Option className="month-item" key={`${index}`}>
                                    {months[index]}
                                    </Select.Option>,
                                );
                            }
                            const month = value.month();

                            const year = value.year();
                            const options = [];
                            for (let i = year - 10; i < year + 10; i += 1) {
                                options.push(
                                    <Select.Option key={i} value={i} className="year-item">
                                    {i}
                                    </Select.Option>,
                                );
                            }
                            return (
                                <div style={{ padding: 8 }}>
                                    <Typography.Title level={4}>Tax Calendar</Typography.Title>
                                    <div style={{ padding: 8, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Row gutter={8}>
                                            <Col>
                                                <Radio.Group size="small" onChange={e => onTypeChange(e.target.value)} value={type}>
                                                    <Radio.Button value="month">Month</Radio.Button>
                                                    <Radio.Button value="year">Year</Radio.Button>
                                                </Radio.Group>
                                            </Col>
                                            <Col>
                                                <Select
                                                size="small"
                                                dropdownMatchSelectWidth={false}
                                                className="my-year-select"
                                                onChange={newYear => {
                                                    const now = value.clone().year(newYear);
                                                    onChange(now);
                                                }}
                                                value={String(year)}
                                                >
                                                {options}
                                                </Select>
                                            </Col>
                                            <Col>
                                                <Select
                                                size="small"
                                                dropdownMatchSelectWidth={false}
                                                value={String(month)}
                                                onChange={selectedMonth => {
                                                    const newValue = value.clone();
                                                    newValue.month(parseInt(selectedMonth, 10));
                                                    onChange(newValue);
                                                }}
                                                >
                                                {monthOptions}
                                                </Select>
                                            </Col>
                                        </Row>
                                        {
                                            fullscreen ? (
                                                <CompressOutlined style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => setFullscreen(!fullscreen)} />
                                            ) : (
                                                <ExpandOutlined style={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => setFullscreen(!fullscreen)} />
                                            )
                                        }
                                    </div>
                                </div>
                            );
                        }}
                    />
                </Col>
            </Row>
        </div>

    )
}

export default Dashboard
