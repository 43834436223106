import React, { useState, useEffect, useRef, useContext } from 'react'
import { Descriptions, Button, Table, Typography, Space, Form, Input, Popconfirm, Row, Col, Upload, message, Comment, Tooltip, List, Avatar } from 'antd'
import moment from 'moment';
import { InboxOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2'

import firebase from '../../firebaseConfig'
import { formatDate } from '../../services/dataHandlers'

const { Link, Title } = Typography
const { Dragger } = Upload
const { TextArea } = Input;

const storageRef = firebase.storage().ref()
const db = firebase.firestore()

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false)
    const inputRef = useRef(null)
    const form = useContext(EditableContext);

    useEffect(() => {
        if(editing) {
            inputRef.current.focus();
        }
    }, [editing])

    const toggleEdit = () => {
        setEditing(!editing)
        form.setFieldsValue({
            [dataIndex]: record[dataIndex]
        })
    }

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values})
        } catch(errInfo) {
            console.log("Save Failed", errInfo)
        }
    }

    let childNode = children;
    if(editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`
                    }
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        )
    }

    return <td {...restProps}>{childNode}</td>
}



function ReviewList({location, data, close, reviewStatus }) {
    const taxReviews = useSelector(state => state.taxReviews)
    const { state } = location
    const history = useHistory()
    const [fileForm] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [value, setValue] = useState('')
    const [urlList, setUrlList] = useState([])
    const [addFiles, setAddFiles] = useState([])
    const auth = useSelector(state => state.auth)
    const [record, setRecord] = useState(state?.data)
    const [issue, setIssue] = useState(state?.data?.issues || [])
    const [hasPendingIssue, setHasPendingIssue] = useState(false)
    const [activeIssue, setActiveIssue] = useState(null)
    const [comments, setComments] = useState(state.data?.comments || [])
    const [count, setCount] = useState(state.data?.issues?.length || 0)

    const columns = [
        {
            title: "Item No",
            dataIndex: "itemNo",
            key: "itemNo"
        },
        {
            title: "Inquiry / Tax Issue",
            dataIndex: "issue",
            key: "issue",
            editable:  true
        },
        {
            title: "Reference",
            dataIndex: "reference",
            key: "reference",
            editable: true
        },
        {
            title: "Status",
            dataIndex: "issueStatus",
            key: "issueStatus"
        },
        {
            title: "Date Closed",
            dataIndex: "dateClosed",
            key: "dateClosed",
            render: (text) => {
                return formatDate(text?.toDate()?.toString())
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 200,
            render: (_, record) => {
                return auth.accessRole === "Requestor" || auth.accessRole === "Supervisor"
                    ? <></>
                    : record.isNew
                        ? (<Space>
                            <Popconfirm title="Save Inquiry?" onConfirm={() => updateTaxReview()} >
                                <Button size="small" type="primary">Save</Button>
                            </Popconfirm>
                            <Popconfirm title="Delete Inquiry?" onConfirm={() => handleDeleteRow(record.key)}>
                                <Button size="small">Delete</Button>
                            </Popconfirm>
                        </Space>)
                        : (<Space>
                            <Popconfirm title="Close Inquiry?" onConfirm={() => handleCloseIssue(record, "Closed")} >
                                <Button size="small" type="primary">Close</Button>
                            </Popconfirm>
                            <Popconfirm title="Pending Close Inquiry??" onConfirm={() => handleCloseIssue(record, "For Adjustment Next Period")}>
                                <Button size="small">For Adjustment Next Period</Button>
                            </Popconfirm>
                        </Space>)
            }
        }
    ]

    const handleCloseIssue = (data, value) => {
        console.log(record)
        const newIssueData = { ...data }
        newIssueData.issueStatus = value
        newIssueData.dateClosed = firebase.firestore.Timestamp.fromDate(new Date())
        console.log(newIssueData)

        const newIssueList = [ ...issue ]
        newIssueList[data.key] = { ...newIssueData }
        setIssue(newIssueList)
        console.log(newIssueList)

        db.collection("taxReviews").doc(record._id).update({
            issues: newIssueList
        }).then(res => {
            Swal.fire({
                title: "Issue Closed",
                icon: "success"
            })
        })
    }

    const handleAddRow = () => {
        const newData = {
            key: count,
            itemNo: count + 1,
            issue: "Enter Inquiry / Tax Issue",
            reference: "Enter Reference",
            isNew: true,
            issueStatus: "Pending",
            comments: []
        }
        setIssue([...issue, newData])
        setCount(count + 1)
    }

    const handleDeleteRow = key => {
        const filter = issue.filter(iss => iss.key !== key)
        setIssue(filter)
        setCount(count - 1)
    }

    const handleSave = row => {
        const newData = [...issue]
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setIssue(newData)
    }

    const updateTaxReview = () => {
        const oldIssues = issue.map(iss => {
            const data = { ...iss }
            data.isNew = false
            return data
        })

        db.collection("taxReviews").doc(record._id).update({
            issues: oldIssues
        })
    }

    useEffect(() => {
        const hasPending = issue.filter(iss => iss.issueStatus === "Pending").length > 0
        setHasPendingIssue(hasPending)

        // Initial Attachments
        const urlList = record.initialFiles?.map( async (fileRef) => {
            const filename = fileRef.split("/")
            const url = await storageRef.child(fileRef).getDownloadURL()
            return {url, fileName: filename[2]}
        })
    
        Promise.all(urlList)
            .then(result => {
                setUrlList(result)
            })
            .catch(err => console.log(err))

        const addList = record.additionalFiles?.map(async (fileRef) => {
            const filename = fileRef.split("/")
            const url = await storageRef.child(fileRef).getDownloadURL()
            return {url, fileName: filename[2]}
        })

        Promise.all(addList)
            .then(result => {
                setAddFiles(result)
            })
            .catch(err => console.log(err))
    }, [issue])

    const approveTaxReview = () => {
        Swal.fire({
            title: "Approve?",
            text: "Do you want to approve the tax review?",
            icon: "warning",
            confirmButtonText: "Approve",
            confirmButtonColor: "#1890ff",
            showCancelButton: true
        })
        .then(res => {
            if(res.isConfirmed) {
                Swal.fire({ icon: "success"})
                db.collection("taxReviews").doc(record._id).set({ ...record, status: "Closed", reviewer: auth.name, dateClosed: firebase.firestore.Timestamp.fromDate(new Date()) })
                    .then(() => {
                        history.goBack()
                    })
            }
        })
    }

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell
        }
    }

    const columnlist = columns.map((col) => {
        if(!col.editable) {
            return col
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: record.isNew ? col.editable : false,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSave: handleSave
            })
        }
    })

    const onFinish = values => {
        const additionalFiles = { ...values }
        const fileList = []
        
        additionalFiles.additionalAttachments.fileList.map(file => fileList.push(file.originFileObj))

        const request = fileList.map( async file => {
            const addFileRef = storageRef.child(`taxReviews/${record._id}/${file.name}`)
            const response = await addFileRef.put(file)
            return response.metadata.fullPath;
        })

        Promise.all(request)
            .then(async result => {
                message.success("Additional files uploaded successfully")
                const additionalFileList = [ ...record.additionalFiles, ...result]
                console.log(additionalFileList)
                db.collection("taxReviews").doc(record._id).update({
                    additionalFiles: additionalFileList
                })
                .then(() => {
                    fileForm.resetFields()
                    history.goBack()
                })
            })
    }

    const handleChangeComment = (e) => {
        setValue(e.target.value)
    }

    const handleSubmitComment = () => {
        const newComment = {
            author: auth.name,
            content: value,
            datetime: firebase.firestore.Timestamp.fromDate(new Date()),
        }
        console.log(issue)
        console.log(activeIssue.key, newComment)

        const newData = [ ...issue ];
        newData[activeIssue.key].comments = [ ...comments, newComment ]

        console.log(newData)

        db.collection("taxReviews").doc(record._id).update({
            issues: newData
        })
            .then(res => {
                setComments([ ...comments, newComment ])
                setValue("")
            })
            .catch(err => console.log(err))
    }

    return (
        <div style={{backgroundColor: "white", padding: "24px 24px"}}>
            <Descriptions
                title={<span style={{ color: record.isLate ? "red" : "" }}>{record._id}</span>}
                column={2}
                extra={ auth.accessRole !== "Requestor"
                ? <Space>
                        <Button type="primary" onClick={() => approveTaxReview()} disabled={hasPendingIssue || record.status === "Closed"} >{record.status === "Pending" ? "Approve" : "Closed"}</Button>
                        <Button onClick={() => handleAddRow()} disabled={record.status === "Pending" ? false : true}>Add Issue</Button>
                        <Button onClick={() => history.goBack()}>Close</Button>
                    </Space>
                : <></>
                }
            >
                <Descriptions.Item label="Company Name">{record.company}</Descriptions.Item>
                <Descriptions.Item label="Entity Representative">{record.submittedBy}</Descriptions.Item>
                <Descriptions.Item label="Review of">{record.taxType}</Descriptions.Item>
                <Descriptions.Item label="Reviewed By">{record.reviewer}</Descriptions.Item>
                <Descriptions.Item label="Taxable Period"><span style={{ color: record.isLate ? "red" : "" }}>{record.taxablePeriod}</span></Descriptions.Item>
                <Descriptions.Item label="Review Status">{record.status}</Descriptions.Item>
            </Descriptions>
            <br />
            <Table 
                components={components}
                rowClassName={() => 'editable-row'}
                bordered
                dataSource={issue}
                columns={columnlist}
                onRow={(data) => {
                    return {
                        onClick: event => {
                            setActiveIssue(data)
                            setComments(data.comments)
                        }
                    }
                }}
            />
            <br />
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Title level={4}>Initial Attachments: </Title>
                    <ul>
                    {
                        urlList.map(url => {
                            if(typeof url !== "undefined") {
                                return(<li><Link onClick={() => window.open(url.url, "_blank")} >{url.fileName}</Link></li>)
                            }     
                        })
                    }
                    </ul>
                </Col>
                <Col span={6}>
                    <Title level={4}>Additional Attachments: </Title>
                    <Form onFinish={onFinish} form={fileForm}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <Form.Item
                                name="additionalAttachments"
                            >
                                <Dragger
                                    beforeUpload={() => false}
                                    multiple
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                        band files
                                    </p>
                                </Dragger>   
                            </Form.Item>
                            <Space>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary">Save</Button>
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="reset">Clear</Button>
                                </Form.Item>
                            </Space>
                        </div>
                    </Form>
                </Col>      
                <Col span={6}>
                    <Title level={4}>{state.reviewStatus === "closed" ? 'Additional Documents' : `\xa0`}</Title>
                    <ul>
                        {
                            addFiles.map(url => {
                                return(<li><Link onClick={() => window.open(url.url, "_blank")} >{url.fileName}</Link></li>)
                            })
                        }
                    </ul>
                </Col>
                <Col span={6}>
                    <List
                        className="comment-list"
                        header={ activeIssue !== null ? activeIssue?.issue : "Select an Issue by clicking a row." }
                        itemLayout="horizontal"
                        dataSource={comments}
                        renderItem={item => (
                        <li>
                            <Comment
                                // actions={item.actions}
                                author={item.author}
                                // avatar={item.avatar}
                                content={(<p>{item.content}</p>)}
                                // datetime={item.datetime}
                                />
                        </li>
                        )}
                    />
                    <Comment
                        avatar={
                            <Avatar
                            src={ auth.avatar }
                            alt="Han Solo"
                            />
                        }
                        content={
                            <>
                                <Form.Item>
                                    <TextArea rows={4} onChange={handleChangeComment} value={value} />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" loading={submitting} onClick={handleSubmitComment} type="primary">
                                        Add Comment
                                    </Button>
                                </Form.Item>
                            </>
                        }
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ReviewList
