import { ADD_COMPANY, UPDATE_COMPANY, REMOVE_COMPANY } from '../types'

const initialState = []

const companies = (state = initialState, action) => {
    switch(action.type) {
        case ADD_COMPANY:
            const addedState = [...state]
            for (const obj of action.payload) {
                addedState.push(obj)
            }
            return [...addedState]
        case UPDATE_COMPANY:
            if (action.payload.shortName) {
                const modifiedState = [
                    ...state.filter(obj => obj.shortName && obj.shortName !== action.payload.shortName)
                ]
                modifiedState.push(action.payload)
                return [...modifiedState]
            }
            break;
        case REMOVE_COMPANY:
            let removedState = [...state]
            removedState = removedState.filter(obj => obj.shortName !== action.payload.shortName)
            return [...removedState]
        default:
            return [...state]
    }
}

export default companies