const formatDate = date => {
    if (!date) {
        return ''
    } else {
        const dateArray = date?.split(' ')
        const month = dateArray[1]
        const day = dateArray[2]
        const year = dateArray[3]
        return `${month} ${day}, ${year}`
    }
}

export {
    formatDate
}
  