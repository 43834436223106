import React, { useState } from 'react'
import { Button, Space, Form, Input, Row, Col, Select, message } from 'antd'
import { useSelector } from 'react-redux'

import firebase from '../../firebaseConfig'

const { Option } = Select;
const collection = firebase.firestore().collection("users")

function UserHeader({ action, record, closeHeader, setAction }) {
    const [form] = Form.useForm()
    const companies = useSelector(state => state.companies)
    const [accessRole, setAccessRole] = useState(null)
    const [disable, setDisable] = useState(true)
    const initialState = { ...record }

    const ActionButtons = () => {
        switch(action) {
            case "Add":
                return (
                    <Space>
                        <Button type="primary" onClick={() => form.submit()}>Add</Button>
                        <Button onClick={() => closeHeader()} >Cancel</Button>
                    </Space>
                )
            case "Update":
                return (
                    <Space>
                        <Button onClick={() => form.submit()} type="primary">Save</Button>
                        <Button onClick={() => { form.resetFields(); closeHeader()}} >Cancel</Button>
                    </Space>
                )
            default:
                return null
                break;
        }
    }

    const onFinish = values => {
        console.log(values)
        if(action === "Update") {
            collection.doc(values.email).set(values)
                .then(res => {
                    form.resetFields()
                    closeHeader()
                    return message.success("User successfully updated.")
                })
                .catch(err => {
                    console.log(err)
                    return message.error("Oops! Something went wrong")
                })
        } else {
            collection.doc(values.email).get()
                .then(result => {
                    if(result.exists) {
                        return message.error("User already exists.")
                    }
                    collection.doc(values.email).set(values)
                        .then(res => {
                            form.resetFields()
                            return message.success("User successfully added.")
                        })
                        .catch(err => {
                            console.log(err)
                            return message.error("Oops! Something went wrong")
                        })
                })
                .catch(err => {
                    console.log(err)
                    return message.error("Oops! Something went wrong")
                })
        }
    }

    return (
        <div className="user-header-container">
            <div className="action-bar">
                <div className="action-header">{record === undefined ? "" : record?.taxName}</div>
                <div className="action-buttons">
                    <ActionButtons />
                </div>
            </div>
            <br />
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={onFinish}
                initialValues={initialState}
            >
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: "Please enter name" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ required: true, message: "Please enter email" }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Access Role"
                            name="accessRole"
                            rules={[{ required: true, message: "Please select access role" }]}
                        >
                            <Select onChange={(e) => setAccessRole(e)}>
                                <Option key="Requestor" value="Requestor">Requestor</Option>
                                <Option key="Tax Reviewer" value="Tax Reviewer">Tax Reviewer</Option>
                                <Option key="Supervisor" value="Supervisor">Supervisor</Option>
                                <Option key="System Admin" value="System Admin">System Admin</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        {
                            accessRole !== "Supervisor"
                                ? (<>
                                    <Form.Item
                                        label="Immediate Supervisor"
                                        name="supervisor"
                                        rules={[{ required: accessRole !== "Supervisor" ? true : false, message: "Please enter immediate supervisor" }]}
                                    >
                                        <Input defaultValue=""/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Supervisor's Email"
                                        name="supervisorEmail"
                                        rules={[{ required: accessRole !== "Supervisor" ? true : false, message: "Please enter supervisor's email" }]}
                                    >
                                        <Input defaultValue=""/>
                                    </Form.Item>
                                </>) : <></>
                        }
                        {
                            accessRole === "Supervisor"
                                ? <Form.Item
                                        label="Companies"
                                        name="companies"
                                        rules={[{ required: true, message: "Please select a company" }]}
                                    >
                                        <Select mode="multiple">
                                            {
                                                companies.map(company => {
                                                    return(<Option value={company.shortName}>{company.companyName}</Option>)
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                : <></>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default UserHeader
